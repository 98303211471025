import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { Container } from 'reactstrap';

import SEO from '../components/SEO';
import ArrowLink from '../components/ArrowLink';
import { COLORS } from '../constants/style';

const Page = styled.div`
  padding-top: 120px;
  margin-bottom: 120px;

  .pic-side {
    margin-top: 3em;
    @media (max-width: 576px) {
      margin-top: 0;
    }
    margin-bottom: 1em;
    img {
      width: 100%;
    }
  }

  .content {
    text-align: justify;
    padding: 3em;
    @media (max-width: 576px) {
      padding: 1em;
    }
    h3 {
      margin-bottom: 0;
    }
    .job-title {
      color: ${COLORS.textGray};
    }

    .biography {
      margin-top: 2em;
    }
  }
`;

const TeamBiography = ({ data }) => {
  const {
    name,
    jobTitle,
    biography,
    photoFun,
    seoMetaTags,
    slug,
  } = data.member;
  return (
    <>
      <SEO meta={seoMetaTags} />
      <Page>
        <Container>
          <div className="row no-gutters">
            <div className="col-md-3 pic-side">
              <img src={photoFun.url} alt="Member Pic" />
            </div>
            <div className="col-md-9">
              <div className="content">
                <h3>
                  <strong>{name}</strong>
                </h3>
                <h5 className="job-title">{jobTitle}</h5>
                <div
                  className="biography"
                  dangerouslySetInnerHTML={{ __html: biography }}
                />
                <ArrowLink left className="mt-2" to={`/about#${slug}`}>
                  Back to Edcellent Team
                </ArrowLink>
              </div>
            </div>
          </div>
        </Container>
      </Page>
    </>
  );
};

export const blogQuery = graphql`
  query($slug: String!) {
    member: datoCmsTeamMember(slug: { eq: $slug }) {
      name
      slug
      jobTitle
      biography
      photoFun {
        url
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;

export default TeamBiography;
